import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input} from '@angular/core';
import {configs} from '../../../configs';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-user-link',
  templateUrl: './user-link.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserLinkComponent implements OnInit {
  @Input() page: 'subscribe' | 'register' | 'myAccount';
  @Input() iconClassName: string;

  public pages = {
    subscribe: {
      isExternal: false,
      className: 'btn btn-full sport-color-bg sport-color-border',
      label: 'subscribe',
      icon: 'subscribe',
      iconClassName: null,
      path: '/subscribe'
    },
    register: {
      isExternal: true,
      className: 'btn btn-full sport-color-bg sport-color-border',
      label: 'register',
      icon: 'register',
      iconClassName: null,
      path: environment.cloudPayPath + configs.cloudPay.urls.registration
    },
    myAccount: {
      isExternal: true,
      className: 'btn btn-full',
      label: 'my_account',
      icon: null,
      iconClassName: null,
      path: environment.cloudPayPath + configs.cloudPay.urls.myAccount
    }
  };


  constructor() {
  }

  ngOnInit(): void {
  }

}
