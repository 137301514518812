import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UserAuthService } from 'amg-fe-angular/services/user-auth/user-auth.service';
import { UserState } from 'amg-fe-angular/types/payments/user-auth.type';
import { SubSink } from 'subsink';
import { WordpressService } from '../../services/wordpress.service';
import { CommonData } from '../../types/wordpress/common-data.type';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  public menuOpened: boolean;
  public searchOpened: boolean;
  public isLoggedIn: boolean;
  private subSink: SubSink;
  public commonData: CommonData;
  public formSearch: FormGroup = new FormGroup({
    q: new FormControl('')
  });

  constructor(
    private router: Router,
    private wordpressService: WordpressService,
    private userAuthService: UserAuthService,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {


    this.subSink = new SubSink();
    this.menuOpened = false;
    this.searchOpened = false;
  }

  ngOnInit(): void {


    this.subSink.add(this.userAuthService.userStateObservable.subscribe(userState => {

      if (userState) {
        this.isLoggedIn = userState.state !== UserState.notLoggedIn;
        this.cd.markForCheck();
      }
    }));

    this.subSink.add(this.wordpressService.getCommonData<CommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;
          this.cd.markForCheck();
        }
      }));

    this.subSink.add(this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeMenu();
      }));
  }


  public openMenu() {
    this.menuOpened = true;
    window.scrollTo(0, 0);
    this.toggleOverlayClass();
    // trigger refresh html...
    this.cd.markForCheck();
  }

  public closeMenu() {
    this.menuOpened = false;

    this.toggleOverlayClass(true);
    // trigger refresh html...
    this.cd.markForCheck();
  }

  private toggleOverlayClass(remove?: boolean): void {

    if (remove) {

      this.renderer.removeClass(document.body.parentElement, 'overlay-opened');

      // setTimeout(() => {
      //   this.renderer.removeClass(document.body.parentElement, 'overlay-opened');
      // }, 700);
    } else {

      this.renderer.addClass(document.body.parentElement, 'overlay-opened');
    }
  }

  public searchToggle() {

    this.toggleOverlayClass(this.searchOpened);
    this.searchOpened = !this.searchOpened;
    this.cd.markForCheck()
  }

  public submitSearch(event: Event, inputElement: HTMLInputElement): void {
    event.preventDefault();
    const filters = {...this.formSearch.value};
    Object.keys(filters).forEach((key) => (!filters[key] || filters[key] === '') && delete filters[key]);

    this.router.navigate(['ondemand'], {queryParams: filters});
    this.searchToggle();
    this.formSearch.reset();
    inputElement.blur();
  }

  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }
}
