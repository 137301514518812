<div class="modal" [class.opened]="isOpened" *ngIf="texts">
  <div class="modal-content">

    <amg-loader *ngIf="isLoading" class="pabsolute"></amg-loader>

    <div class="close-btn icon-cross" (click)="closeModel()"></div>
    <div class="modal-header">
      <h1 [innerHTML]="loginLabels.title" class="title"></h1>
      <div [innerHTML]="loginLabels.description" class="description"></div>
    </div>
    <div class="box-data">
      <div class="content">
        <form [formGroup]="loginForm" (submit)="formSubmit()">
          <fieldset>
            <div><label for="email" [innerHTML]="loginLabels.email.title | label"></label></div>
            <input id="email" formControlName="emailaddress" type="email" autocomplete="login-email"
                   [placeholder]="loginLabels.email.input"/>
            <div class="error-txt" *ngIf="isFieldInvalid('emailaddress')">
              <span *ngIf="loginForm.controls['emailaddress'].hasError('required')"
                    [textContent]="loginLabels.email.error_required"></span>
              <span *ngIf="loginForm.controls['emailaddress'].hasError('email')"
                    [textContent]="loginLabels.email.error"></span>
              <span *ngIf="loginForm.controls['emailaddress'].hasError('server')"
                    [textContent]="loginForm.controls['emailaddress'].getError('server')"></span>
            </div>
          </fieldset>
          <fieldset>
            <div><label for="password" [innerHTML]="loginLabels.password.title | label"></label></div>
            <input id="password" formControlName="password" type="password" autocomplete="login-password"
                   [placeholder]="loginLabels.password.input"/>
            <div class="error-txt" *ngIf="isFieldInvalid('password')">
              <span *ngIf="loginForm.controls['password'].hasError('required')"
                    [textContent]="loginLabels.password.error_required"></span>
              <span *ngIf="loginForm.controls['password'].hasError('minlength')"
                    [textContent]="loginLabels.password.error"></span>
              <span *ngIf="loginForm.controls['password'].hasError('server')"
                    [textContent]="loginForm.controls['password'].getError('server')"></span>
            </div>
          </fieldset>
          <div>

            <button type="submit" class="btn sport-color-border">
              <i class="sport-color-txt icon icon-login"></i>
              <span [textContent]="'login' | label"></span>
            </button>
            <div class="error-txt" *ngIf="serverErrors.length">
              <span *ngFor="let error of serverErrors" [textContent]="error"></span>
            </div>
          </div>
        </form>
        <div class="txt-forgot-pwd">
          <a [href]="resetPwdUrl" [textContent]="loginLabels.link_forgot_password"></a>
        </div>
      </div>
      <div class="bottom">

        <div class="txt-new-account">
          <div><strong [textContent]="loginLabels.new_account.title"></strong></div>
          <div class="description" [textContent]="loginLabels.new_account.description"></div>
          <app-user-link page="register"></app-user-link>
        </div>
      </div>
    </div>
  </div>
</div>

