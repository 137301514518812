import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: 'sports',
    loadChildren: () => import('./pages/sports/sports.module')
      .then(m => m.SportsModule),
  },
  {
    path: 'ondemand',
    loadChildren: () => import('./pages/ondemand/ondemand.module')
      .then(m => m.OndemandModule)
  },
  {
    path: 'player/vod',
    loadChildren: () => import('./pages/player/vod/vod.module')
      .then(m => m.VodModule)
  },
  {
    path: 'player/live',
    loadChildren: () => import('./pages/player/live/live.module')
      .then(m => m.LiveModule)
  },
  {
    path: 'player/multicam',
    loadChildren: () => import('./pages/player/multicam/multicam.module')
      .then(m => m.MulticamModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module')
      .then(m => m.EventsModule),
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./pages/subscribe/subscribe.module')
      .then(m => m.SubscribeModule),
  },
  {path: 'terms-conditions', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)},
  {path: 'needhelp', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)},
  {path: 'privacy-policy', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)},
  {path: 'cookie-policy', loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)},
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomeModule),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
