// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  streamPlay: {
    baseUrl: 'https://staging.api.streamplay.streamamg.com/fixtures/',
    // baseUrl: null,
  },

  wordpress: {
    api: 'https://wp-devmatchroom.streamamg.com/wp-json/wpa/v1'
  },

  // base Url where the website is deployed, used for Payment integration template
  baseUrl: 'https://devmatchroom.streamamg.com',
  cloudPayPath: 'https://stagingmatchroom-payments.streamamg.com'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
