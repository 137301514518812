import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {WordpressService} from '../services/wordpress.service';
import {Subscription} from 'rxjs';
import {Label} from '../types/label.type';

@Pipe({
  name: 'label'
})
export class LabelPipe implements PipeTransform, OnDestroy {
  private labelsSubscription: Subscription;
  private labels: Label[];

  constructor(private wordpressService: WordpressService) {
    this.labels = [];

    this.labelsSubscription = this.wordpressService.getLabels().subscribe(data => {

      if (data) {
        this.labels = data;
      }
    });
  }

  transform(value: any, ...args: any[]): any {
    return this.labels[value] || value;
  }

  ngOnDestroy(): void {
    this.labelsSubscription.unsubscribe();
  }
}
