import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {CommonData} from '../../types/wordpress/common-data.type';
import {SubSink} from 'subsink';
import {WordpressService} from '../../services/wordpress.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieBannerComponent implements OnInit, OnDestroy {

  private subSink: SubSink;
  public commonData: CommonData;
  public showBanner: boolean;

  constructor(
    private wordpressService: WordpressService,
    private cd: ChangeDetectorRef
  ) {


    this.subSink = new SubSink();
    this.showBanner = false;
  }

  ngOnInit(): void {


    this.subSink.add(this.wordpressService.getCommonData<CommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;


          if (!localStorage.getItem('cookie-banner-closed')) {
            this.showBanner = true;
          }

          this.cd.markForCheck();
        }
      })
    );
  }


  public closeBanner() {
    localStorage.setItem('cookie-banner-closed', '1');
    this.showBanner = false;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }

}
