import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './core/shared.module';
import { HeaderComponent } from './core/components/header/header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { LogoutBtnComponent } from './core/components/user-auth/logout-btn/logout-btn.component';
import { LoginModalComponent } from './core/components/user-auth/login-modal/login-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderModule } from 'amg-fe-angular/modules/loader/loader.module';
import { CookieBannerComponent } from './core/components/cookie-banner/cookie-banner.component';
import { StreamPlayLiveModule } from '@streamamg/stream-play-lib/src/lib/live-service/stream-play-live.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LogoutBtnComponent,
    LoginModalComponent,
    CookieBannerComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    LoaderModule,
    StreamPlayLiveModule,
  ],
  exports: [BrowserAnimationsModule],
  bootstrap: [AppComponent],
})
export class AppModule {
}
