import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import MobileDetect from 'mobile-detect';

if (environment.production) {
  enableProdMode();
}

const mobileDetect = new MobileDetect(window.navigator.userAgent);
(window as any).isMobileDevice = mobileDetect.mobile();
if ((window as any).isMobileDevice) {
  document.body.parentElement.classList.add('mobile-device');
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
