import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {DateFormattedPipe} from 'amg-fe-angular/pipes/date-formatted.pipe';
import {LoaderModule} from 'amg-fe-angular/modules/loader/loader.module';
import {IsExternalLinkPipe} from 'amg-fe-angular/pipes/is-external-link.pipe';
import {LabelPipe} from './pipes/label.pipe';
import {LoginBtnComponent} from './components/user-auth/login-btn/login-btn.component';
import { UserLinkComponent } from './components/user-auth/user-link/user-link.component';


@NgModule({
  declarations: [
    IsExternalLinkPipe,
    DateFormattedPipe,
    LabelPipe,
    LoginBtnComponent,
    UserLinkComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    LoaderModule
  ],
  exports: [
    IsExternalLinkPipe,
    DateFormattedPipe,
    LabelPipe,
    LazyLoadImageModule,
    LoginBtnComponent,
    UserLinkComponent
  ]
})
export class SharedModule {
}
