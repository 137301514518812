export const configs = {

  carouselItemSize: 25,
  playlistPageSize: 50,
  generalContentPageSize: 24,
  fixturesContentPageSize: {
    default: 24,
    uhd: 25,
    // uhd: 30
  },
  browseContentPageSize: {
    default: 24,
    fhd: 25,
    qhd: 30,
    uhd: 35
  },

  streamPlayPartnerID: 3001403,
  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3001403',
    uiConfId: '30028346', // generic player
    flashvars: {
      'playerSeekButtonAMG.plugin': true,
      'currentTimeLabel.order': 10,
      'liveStatus.order': 11,
      'durationLabel.order': 12,

      'playPauseBtn.order': 40,

      'volumeControl.order': 100,
      'chromecast.order': 80,
      'sourceSelector.order': 70,
      'fullScreenBtn.parent': 'videoHolder',
      // 'volumeControl.showSlider': false,
      IframeCustomPluginCss1: '/assets/css/video-player.css',
      multicamPlayerCustomCss: '/assets/css/multicam-player.css',
      vast: {'prerollUrl': 'https://bds.spearad.video/delivery/12/22'}
    },
    uiConfs: {
      pool: '30028101',
      snooker: '30028102',
      darts: '30028103',
      boxing: '30028106',
      ten_pin_bowling: '30028109',
      ping_pong: '30028111',
      fishing: '30028112',
      gymnastics: '30028114',
      netball: '30028117',
      basketball: '30028120',
      golf: '30028123',
      poker: '30028858'
    }
  },

  cloudPay: {
    urls: {
      myAccount: '/account',
      reset: '/account/reset',
      registration: '/account/freeregistration'
    }
  },

  wordpress: {
    pages: {
      home: '/section?slug=home',
      sports: '/section?slug=sports',
      sport: '/section?slug=sport-%sportid%',
      events: '/section?slug=events',
      subscribe: '/section?slug=subscribe',
      ondemand: '/section?slug=ondemand',
      video: '/section?slug=video'
    },
    contactForm: '/section?slug=contact-form',
    sport: '/sport',
    playlist: '/playlist',
    commonData: '/common_field?slug=common-fields'
  }
};
