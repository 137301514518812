<header [class.menu-opened]="menuOpened" [class.search-opened]="searchOpened">
  <div class="logo">
    <a routerLink="/"></a>
  </div>

  <div (click)="closeMenu()" class="close-menu-overlay"></div>
  <div id="header-menu" class="mobile-overlay">
    <div (click)="closeMenu()" class="close-btn icon-cross"></div>
    <div id="header-menu-content">
      <nav>
        <ng-container *ngFor="let item of commonData.header.menu">
          <div *ngIf="item.title && item.link">
            <ng-container *ngIf="item.link | isExternalLink; else internal">
              <a class="external" [attr.href]="item.link" target="_blank" [textContent]="item.title"></a>
            </ng-container>
            <ng-template #internal>
              <a routerLinkActive="selected" [routerLink]="item.link" [textContent]="item.title"></a>
            </ng-template>
          </div>
        </ng-container>
      </nav>
      <div class="secondary-menu">
        <ng-container *ngIf="isLoggedIn; else notLogged">
          <div class="button button-my-account">
            <app-user-link page="myAccount"></app-user-link>
          </div>
          <div class="button">
            <app-logout-btn></app-logout-btn>
          </div>
        </ng-container>
        <ng-template #notLogged>
          <div class="button">
            <app-login-btn iconClassName="sport-color-txt"
                           label="{{'login' | label}} <span class='register'>/ {{'register' | label}}</span>"></app-login-btn>
          </div>
          <div class="button">
            <app-user-link page="subscribe" iconClassName="sport-color-txt"></app-user-link>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div id="header-search-container" class="mobile-overlay">
    <div (click)="searchToggle()" class="close-btn icon-cross"></div>
    <div class="search-content">
      <label class="search-lbl" [textContent]="'search' | label"></label>
      <div class="field-container">
        <form [formGroup]="formSearch" (ngSubmit)="submitSearch($event, searchInput)">
          <input #searchInput type="text" formControlName="q" [placeholder]="'search.placeholder' | label">
          <button type="submit" class="icon-search"></button>
        </form>
      </div>
    </div>
  </div>
  <div class="search-toggle" (click)="searchToggle()">
    <i class="icon-search"></i>
  </div>

  <div (click)="openMenu()" class="menu-mobile-icon">
    <div></div>
    <div></div>
    <div></div>
  </div>

</header>
