import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../environments/environment';

import {configs} from '../configs';
import {WordpressService as WordpressServiceAmg} from 'amg-fe-angular/services/wordpress.service';


@Injectable({
  providedIn: 'root'
})
export class WordpressService extends WordpressServiceAmg {


  constructor(httpClient: HttpClient, @Inject(PLATFORM_ID) protected platform: any) {
    super(httpClient, platform);
    super.init(
      environment.wordpress.api,
      `${environment.wordpress.api}${configs.wordpress.commonData}`);
  }
}
