<ng-template #content>
  <i *ngIf="pages[page].icon" class="icon icon-{{pages[page].icon}} {{pages[page].iconClassName}} {{iconClassName}}"></i>
  <span [textContent]="pages[page].label | label"></span>
</ng-template>

<a *ngIf="pages[page].isExternal; else internalLink"
   [ngClass]="pages[page].className" [href]="pages[page].path">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #internalLink>
  <a [ngClass]="pages[page].className"
     [routerLink]="pages[page].isExternal === false ? pages[page].path : []">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>
