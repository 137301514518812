import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {CommonData} from '../../types/wordpress/common-data.type';
import {SubSink} from 'subsink';
import {WordpressService} from '../../services/wordpress.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {

  private subSink: SubSink;
  public commonData: CommonData;

  constructor(
    private wordpressService: WordpressService,
    private cd: ChangeDetectorRef
  ) {


    this.subSink = new SubSink();
  }

  ngOnInit(): void {


    this.subSink.add(this.wordpressService.getCommonData<CommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;
          this.cd.markForCheck();
        }
      }));
  }

  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }
}
