import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {configs} from '../../../configs';
import {LoginModalBaseComponent} from 'amg-fe-angular/modules/login-modal/login-modal-base.component';
import {UserAuthService} from 'amg-fe-angular/services/user-auth/user-auth.service';
import {WordpressService} from '../../../services/wordpress.service';
import {CommonData, LoginLabels} from '../../../types/wordpress/common-data.type';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-login-modal',
  // templateUrl: AmgModulesPathGenerator.deep6('login-modal/login-modal.component.html'),
  templateUrl: 'login-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginModalComponent extends LoginModalBaseComponent implements OnInit, OnDestroy {
  public loginLabels: LoginLabels;

  constructor(
    private wordpressService: WordpressService,
    userAuthService: UserAuthService,
    formBuilder: FormBuilder,
    cd: ChangeDetectorRef) {

    super(
      userAuthService,
      formBuilder,
      cd,
      environment.cloudPayPath + configs.cloudPay.urls.registration,
      environment.cloudPayPath + configs.cloudPay.urls.reset
    );
  }

  ngOnInit() {
    this.wordpressService
      .getCommonData<CommonData>().subscribe(res => {
      this.loginLabels = res.login;
    });
    super.init();
    super.openModalBind();
  }


  ngOnDestroy() {
    super.destroy();
  }
}
