<amg-loader *ngIf="!appReady"></amg-loader>
<ng-container *ngIf="appReady">
  <app-header></app-header>

  <div id="page-container">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
  <app-login-modal class="amg-login-modal"></app-login-modal>
  <app-cookie-banner></app-cookie-banner>
</ng-container>
