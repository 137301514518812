<footer *ngIf="commonData">
  <div class="copyright" [textContent]="commonData.footer.copyright"></div>
  <div class="links">
    <div *ngFor="let item of commonData.footer.links">
      <ng-container *ngIf="item.link | isExternalLink; else internal">
        <a class="external" [attr.href]="item.link" target="_blank" [textContent]="item.title"></a>
      </ng-container>
      <ng-template #internal>
        <a routerLinkActive="selected" [routerLink]="item.link" [textContent]="item.title"></a>
      </ng-template>
    </div>
  </div>
</footer>
