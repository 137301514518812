import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {UserAuthService} from 'amg-fe-angular/services/user-auth/user-auth.service';

@Component({
  selector: 'app-logout-btn',
  templateUrl: './logout-btn.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutBtnComponent implements OnInit {

  constructor(
    public userAuthService: UserAuthService
  ) {
  }

  ngOnInit(): void {
  }

  public doLogout(event) {

    event.preventDefault();
    this.userAuthService.streamPaymentsLogOut();
  }
}
