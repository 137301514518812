import {ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {UserAuthService} from 'amg-fe-angular/services/user-auth/user-auth.service';

@Component({
  selector: 'app-login-btn',
  templateUrl: './login-btn.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginBtnComponent implements OnInit {
  @Input() label: string;
  @Input() iconClassName: string;

  constructor(private userAuthService: UserAuthService) {
  }

  ngOnInit(): void {
  }

  public openLoginModal(event): void {
    event.preventDefault();
    this.userAuthService.openLoginModal();
  }

}
