export class SportColorUtils {
  public static setSport(sport: string) {

    document.body.parentElement.setAttribute('data-sport', sport);

  }

  public static remove() {

    document.body.parentElement.removeAttribute('data-sport');
  }
}
