import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { CssVarsUtils } from 'amg-fe-angular/utils/css-vars-utils';
import { WordpressService } from './core/services/wordpress.service';
import { UserAuthService } from 'amg-fe-angular/services/user-auth/user-auth.service';
import { SportColorUtils } from './core/utils/sport-color-utils';
import { CommonData } from './core/types/wordpress/common-data.type';

declare const dataLayer;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  public appReady: boolean;
  private subSink: SubSink;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private wordpressService: WordpressService,
    private userAuthService: UserAuthService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
  ) {

    this.subSink = new SubSink();
    this.appReady = false;
  }

  ngOnInit(): void {

    // retrieve Common data
    this.subSink.add(this.wordpressService
      .getCommonData()
      .subscribe((wpCommonData: CommonData) => {
        if (wpCommonData) {
          this.appReady = true;
          this.cd.markForCheck();
        }
      }),
    );

    CssVarsUtils.initScreenSizesOnRoot();

    let currentRoute = '';
    this.subSink.add(this.router.events
      .pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd))
      .subscribe((event: NavigationStart | NavigationEnd) => {

        if (event instanceof NavigationStart) {

          if (event.url.indexOf('logout=1') > -1) {
            this.userAuthService.streamPaymentsLogOut();
            this.router.navigate(['']);
          }

          if (currentRoute === event.url.split('/')[1]) {
            this.renderer.addClass(this.document.body.parentElement, 'same-page-navigation');
          } else {
            this.renderer.removeClass(this.document.body.parentElement, 'same-page-navigation');
          }
        } else {
          currentRoute = event.urlAfterRedirects.split('/')[1];
          this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');
          this.trackPageView();
          SportColorUtils.remove();
        }
      }));
  }


  private setPageAttribute(event: RouterEvent, eventUrlKey: string, htmlAttribute: string): void {
    if (event[eventUrlKey] !== '/') {
      const urlTree = this.router.parseUrl(event[eventUrlKey]);
      const page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
      this.renderer.setAttribute(this.document.body.parentElement, htmlAttribute, page);
    }
  }

  private trackPageView(): void {
    // https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/

    // @ts-ignore
    if (window.dataLayer) {
      const currentUrl = new URL(window.location.href);
      const cpUser = JSON.parse(localStorage.getItem('StreamPaymentSessionData'));

      dataLayer.push({
        'event': 'virtualPageview',
        'pageUrl': currentUrl.href,
        'pageTitle': currentUrl.pathname,
        // ...(currentUrl.searchParams.get('partner')) && {'partner': currentUrl.searchParams.get('partner')},
        ...(cpUser?.CurrentCustomerSession?.CustomerId) && {'userId': cpUser?.CurrentCustomerSession?.CustomerId},
      });
    }
  }


  ngOnDestroy(): void {

    this.subSink.unsubscribe();
  }
}
